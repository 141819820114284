import React from 'react';

import { getUserDisplayName, toHumanReadableDate } from 'core/helpers';
import { extractIngest, ReadersIcons } from 'core/helpers/ingest';

import ImageAvatar from 'components/ImageAvatar';

interface IProps {
  ingest: any;
  description: string;
}

const DESCRIPTION_SIZE = 240;

function ReferenceItem({ ingest, description }: IProps) {
  if (description && description.length > DESCRIPTION_SIZE) {
    description = description.substring(0, DESCRIPTION_SIZE) + '...';
  }

  const { ingestName, ingestFullPath, createdAt, creator, readerType } = extractIngest(ingest);

  return (
    <div className="flex items-start gap-[16px] pb-8">
      <img width="22" src={ReadersIcons[readerType]} alt="Icon" />
      <div>
        <h3 className="text-[16px] pb-2">
          <a href={ingestFullPath} target={'_blank'} rel="noreferrer">
            {ingestName}
          </a>
        </h3>
        <div className="flex items-start gap-3 pb-4">
          <div className="text-[14px] text-[#4E637B] flex items-center gap-1.5">
            <ImageAvatar
              image={creator?.image}
              size={20}
              full_name={creator?.full_name}
              fontSize={'[10px]'}
              showCrossIcon={false}
            />
            {getUserDisplayName(creator?.full_name)}
          </div>
          <div className="text-[14px] text-[#4E637B]">{toHumanReadableDate(createdAt)}</div>
          {/* <div className="text-[14px] text-[#4E637B] flex items-center gap-1.5">
            <img src="/icons/custom-icon.svg" alt="Custom" />
            Company
          </div> */}
        </div>
        <p className="text-[16px] text-[#8297AE]">{description}</p>
      </div>
    </div>
  );
}

export default ReferenceItem;
