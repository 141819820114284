import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { Agents } from 'apis/Agents';
import { Upload } from 'apis/Upload';
import { UserProfile } from 'apis/Profile';
import { getFilePath } from 'core/helpers';
import { IAgent } from 'types';
import { allowedImageTypes, DEFAULT_LIMIT } from 'core/constants';

import CustomModal from '../Modal/CustomModal';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

import './style.scss';

interface IAgentUpdateProps {
  agent: IAgent | null;
  isOpen: boolean;
  onClose: () => void;
}

const DEFAULT_USER_FORM: any = {
  name: '',
  embeddings: '',
  llm: '',
  secret_key_id: null,
  k: 4,
  score: 0.3,
  chunk_size: 1024,
  chunk_overlap: 0,
  system: '',
  description: ''
};

const SECRET_SHOULD_NOT_BE_PROVIDED = 'SHOULD_NOT_BE_PROVIDED';

export default function AgentUpdateModal({ isOpen, onClose, agent }: IAgentUpdateProps) {
  const [formPayload, setFormPayload] = useState(DEFAULT_USER_FORM);
  const [errors, setErrors] = useState<any>({});
  const [configData, setConfigData] = useState<any>({});
  const [secretQueryStatus, setSecretQueryStatus] = useState<string>(SECRET_SHOULD_NOT_BE_PROVIDED);

  const [secretKeys, setSecretKeys] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadSecretKeys = async () => {
    const data = await UserProfile.getUserSecrets({ limit: DEFAULT_LIMIT });
    const keys = data?.results || [];
    setSecretKeys(keys);
  };

  const loadProjectCommonInfo = async () => {
    const data = await Agents.projectCommonInfo();

    if (data) {
      setConfigData(data);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormPayload({ ...formPayload, [name]: value });
  };

  useEffect(() => {
    if (isOpen && agent) {
      loadSecretKeys();
      setFormPayload({ ...agent });
      loadProjectCommonInfo();
    }
  }, [isOpen, agent]);

  const isValidateForm = () => {
    const { name, llm, type, image, secret_key_id } = formPayload;
    const newErrors: any = {};

    if (!name.trim()) newErrors.name = 'Name field is required';
    if (!llm) newErrors.llm = 'Model field is required';
    if (!type) newErrors.type = 'Project type field is required';
    if (!image) newErrors.image = 'Image field is required';
    if (secretQueryStatus == 'REQUIRED' && !secret_key_id) {
      newErrors.secret_key_id = 'Secret key field is required';
    }

    const isValid = isEmpty(newErrors);

    if (!isValid) {
      setErrors(newErrors);
    }

    return isValid;
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValidateForm()) {
      return;
    }

    const payload: any = {
      ...formPayload,
      image_id: formPayload.image?.id || null
    };

    if (secretQueryStatus === SECRET_SHOULD_NOT_BE_PROVIDED) {
      delete payload.secret_key_id;
    }

    setIsSubmitting(true);
    const data = await Agents.update(agent?.uid, payload);
    setIsSubmitting(false);

    if (data) {
      window.location.reload();
    }
  };

  const handleImageChange = (image: any) => {
    setFormPayload({ ...formPayload, image: image });
  };

  if (!agent) return null;

  const llms = useMemo(() => {
    let llmList = configData.llms || [];

    if (formPayload.type == 'AGENT') {
      llmList = llmList.filter((obj: any) => obj.is_function_calling == true);
    }

    return llmList;
  }, [configData, formPayload.type]);

  useEffect(() => {
    const llmObj = llms.find((obj: any) => obj.type == formPayload.llm);
    if (llmObj) {
      setSecretQueryStatus(llmObj?.secret_query_status);
    }
  }, [llms, formPayload.llm]);

  const errorText = (text: string | null) => {
    return text && <p className="text-red-500 text-sm mt-1 ml-1">{text}</p>;
  };

  return (
    <CustomModal title="Update Agent" isOpen={isOpen} onClose={onClose}>
      <div className="agent-edit-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-6">
            <label htmlFor="agent-desig" className="text-[13px] block font-normal mb-2 leading-4">
              Agent Name
            </label>
            <input
              value={formPayload.name}
              onChange={handleChange}
              type="text"
              name="name"
              id="agent-desig"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Name"
            />
            {errorText(errors.name)}
          </div>
          <div className="form-group mb-6 select-model">
            <label className="text-[13px] block font-normal mb-2 leading-4">Agent Model</label>
            <select
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              onChange={handleChange}
              value={formPayload.llm}
              id="llm"
              name="llm"
            >
              {llms.map((llmObj: any) => (
                <option key={llmObj.type} className="custom-option" value={llmObj.type}>
                  {llmObj.label}
                </option>
              ))}
            </select>
          </div>
          {secretQueryStatus != SECRET_SHOULD_NOT_BE_PROVIDED && (
            <div className="form-group mb-6 select-model">
              <label className="text-[13px] block font-normal mb-2 leading-4">API Key</label>
              <select
                className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
                onChange={handleChange}
                value={formPayload.secret_key_id || ''}
                id="secret_key_id"
                name="secret_key_id"
              >
                <option value={0} className="custom-option">
                  API Key
                </option>
                {secretKeys.map((secretObj: any) => (
                  <option key={secretObj.id} className="custom-option" value={secretObj.id}>
                    {secretObj.name}
                  </option>
                ))}
              </select>
              {errors?.secret_key_id && <p className="text-red-600">{errors?.secret_key_id}</p>}
            </div>
          )}
          <div className="form-group mb-6">
            <label htmlFor="k" className="text-[13px] block font-normal mb-2 leading-4">
              Top K
            </label>
            <input
              value={formPayload.k}
              onChange={handleChange}
              type="number"
              name="k"
              id="k"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Top K"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="score" className="text-[13px] block font-normal mb-2 leading-4">
              Cutoff Score
            </label>
            <input
              value={formPayload.score}
              onChange={handleChange}
              type="number"
              step="0.01"
              name="score"
              id="score"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Cutoff Score"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="chunk_size" className="text-[13px] block font-normal mb-2 leading-4">
              Chunk Size
            </label>
            <input
              value={formPayload.chunk_size}
              onChange={handleChange}
              type="number"
              step="1"
              name="chunk_size"
              id="chunk_size"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Chunk Size"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="chunk_overlap" className="text-[13px] block font-normal mb-2 leading-4">
              Chunk Overlap
            </label>
            <input
              value={formPayload.chunk_overlap}
              onChange={handleChange}
              type="number"
              step="1"
              name="chunk_overlap"
              id="chunk_overlap"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Chunk Overlap"
            />
          </div>
          <div className="form-group mb-6">
            <label className="text-[13px] block font-normal mb-2 leading-4">System Prompt</label>
            <textarea
              value={formPayload.system || ''}
              onChange={handleChange}
              name="system"
              id="system"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="System Prompt"
            />
            {errors.system && <p className="text-red-600">{errors.system}</p>}
          </div>
          <div className="form-group mb-6">
            <label className="text-[13px] block font-normal mb-2 leading-4">Description</label>
            <textarea
              value={formPayload.description || ''}
              onChange={handleChange}
              name="description"
              id="description"
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Description"
            />
            {errors.description && <p className="text-red-600">{errors.description}</p>}
          </div>
          <ProfileImageInput image={formPayload.image} onChange={handleImageChange} />

          <SpinnerLoader loading={isSubmitting}>
            <button type="submit" className="submitBtn w-full mt-8">
              Save Agent
            </button>
          </SpinnerLoader>
        </form>
      </div>
    </CustomModal>
  );
}

const ProfileImageInput = ({ image, onChange }: any) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const acceptedTypes = useMemo(() => allowedImageTypes.join(','), []);

  const handleUpload = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setUploadProgress(0);

    const data = await Upload.uploadImage(file, setUploadProgress);
    if (data) {
      onChange(data);
    }

    setUploadProgress(0);
  };

  return (
    <div className="rounded-[10px] file-upload-box">
      <div className="text-center block">
        <div className="flex justify-center">
          <div className="mb-2 text-center relative inline-block">
            <img
              src={getFilePath(image?.file_path)}
              alt="Profile"
              className="mx-auto w-32 h-32"
              style={{ height: 100, width: 100 }}
            />
          </div>
        </div>
        {uploadProgress > 0 && (
          <div className="mt-4 mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="mt-2 text-sm text-gray-600">Uploading: {uploadProgress}%</p>
          </div>
        )}
        <div>
          <p className="mb-2 text-[#8297AE] text-[13px]">
            Upload a square sized photo for best result & Maximum image size limit should be 200 KB
            (JPG, PNG, JPEG).
          </p>
          <div className="relative">
            <input
              className="w-[150px] h-[60px] opacity-0	z-[9999] absolute right-0 left-0 mx-auto"
              type="file"
              accept={acceptedTypes}
              onChange={(e) => handleUpload(e)}
            />
            <button className="text-white bg-[#5F6FFF] rounded-[8px] py-[12px] pl-[20px] pr-[16px] inline-flex items-center justify-center gap-[8px] text-[14px] font-medium border-[1px] border-[#5F6FFF] border-solid">
              <img width="10" src="/onboarding/download-icon.svg" alt="Upload Icon" />
              {image ? 'Upload New Image' : 'Upload Image'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
