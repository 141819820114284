import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getFilePath } from 'core/helpers';
import { getRefreshToken } from 'core/helpers/token';
import { Agents } from 'apis/Agents';
import { setAIAgents, setSearchAgents } from 'store/reducers/agentsSlice';
import { AgentEnum, DEFAULT_LIMIT } from 'core/constants';

import { Divider } from '../Divider/Divider';
import ProfileSidebar from 'components/ProfileSettings/ProfileSidebar';
import MessageSidebar from 'components/MessageSidebar/MessageSidebar';

import './Layout.css';

const messageUrlPattern = /^\/agents\/[^/]+\/chat-widget\/[^/]+\/customer-chats$/;

export const Layout = ({ children, messages, showMessages }: any) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const accessToken = useMemo(() => getRefreshToken(), []);

  const aiAgents = useSelector((state: any) => state.agent.aiAgents);
  const searchAgents = useSelector((state: any) => state.agent.searchAgents);

  const isMessageUrl = useMemo(() => {
    if (messageUrlPattern.test(location.pathname)) {
      return true;
    }
    return false;
  }, [location]);

  const loadAgents = async (limit: number, project_type: string, setStoreValue: any) => {
    const data = await Agents.getAgents({ limit, project_type });
    if (data) {
      dispatch(setStoreValue(data.results));
    }
  };

  useEffect(() => {
    if (accessToken && !isMessageUrl) {
      loadAgents(DEFAULT_LIMIT, AgentEnum.AGENT.toString(), setAIAgents);
      loadAgents(DEFAULT_LIMIT, AgentEnum.RAG.toString(), setSearchAgents);
    }
  }, [isMessageUrl]);

  const renderSideBar = () => {
    if (location.pathname === '/profile-settings') {
      return (
        <div className="">
          <ProfileSidebar />
        </div>
      );
    } else if (isMessageUrl) {
      return (
        <div className="">
          <MessageSidebar />
        </div>
      );
    } else {
      return <AgentSideBar aiAgents={aiAgents} searchAgents={searchAgents} />;
    }
  };

  return (
    <div
      className="text-white relative"
      style={{
        background: 'var(--Neutral-9, #121821)',
        display: 'flex',
        height: '100vh'
      }}
    >
      {/* sidebar start */}
      <div className="sidebar w-[296px] custom-scroll">
        <div className="flex justify-center items-center px-6 py-[21px]">
          <Link to={'/agents'}>
            <img className="w-[150px]" src="/brand-logo/logo.png" alt="" />
          </Link>
        </div>
        <Divider />
        {/* Sidebar content */}
        {renderSideBar()}

        {showMessages && <SidebarMessage messages={messages} />}

        <div className="fixed bottom-0 left-0 w-[296px] bg-[#191f28]">
          {location.pathname === '/profile-settings' ? (
            <div className="p-[16px] items-center border-t-2 border-[#222831]">
              <div className="flex gap-5 justify-center">
                <div className="flex gap-2 items-center justify-center">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 10.5V12.5C10 12.7652 9.89464 13.0196 9.70711 13.2071C9.51957 13.3946 9.26522 13.5 9 13.5H2C1.73478 13.5 1.48043 13.3946 1.29289 13.2071C1.10536 13.0196 1 12.7652 1 12.5V1.5C1 1.23478 1.10536 0.98043 1.29289 0.792893C1.48043 0.605357 1.73478 0.5 2 0.5H9C9.26522 0.5 9.51957 0.605357 9.70711 0.792893C9.89464 0.98043 10 1.23478 10 1.5V3.5"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 7H6"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5L6 7L8 9"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <a
                    href={`/logout`}
                    className="text-[#4E637B] text-[14px] font-medium hover:text-white"
                  >
                    <p>Support</p>
                  </a>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 10.5V12.5C10 12.7652 9.89464 13.0196 9.70711 13.2071C9.51957 13.3946 9.26522 13.5 9 13.5H2C1.73478 13.5 1.48043 13.3946 1.29289 13.2071C1.10536 13.0196 1 12.7652 1 12.5V1.5C1 1.23478 1.10536 0.98043 1.29289 0.792893C1.48043 0.605357 1.73478 0.5 2 0.5H9C9.26522 0.5 9.51957 0.605357 9.70711 0.792893C9.89464 0.98043 10 1.23478 10 1.5V3.5"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 7H6"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5L6 7L8 9"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <a
                    href={`/logout`}
                    className="text-[#4E637B] text-[14px] font-medium hover:text-white"
                  >
                    <p>Discord</p>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-[16px] items-center border-t-2 border-[#222831]">
              <div className="flex gap-5 justify-center">
                <div className="flex gap-2 items-center justify-center">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 10.5V12.5C10 12.7652 9.89464 13.0196 9.70711 13.2071C9.51957 13.3946 9.26522 13.5 9 13.5H2C1.73478 13.5 1.48043 13.3946 1.29289 13.2071C1.10536 13.0196 1 12.7652 1 12.5V1.5C1 1.23478 1.10536 0.98043 1.29289 0.792893C1.48043 0.605357 1.73478 0.5 2 0.5H9C9.26522 0.5 9.51957 0.605357 9.70711 0.792893C9.89464 0.98043 10 1.23478 10 1.5V3.5"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 7H6"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5L6 7L8 9"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <a
                    href={`/logout`}
                    className="text-[#4E637B] text-[14px] font-medium hover:text-white"
                  >
                    <p>Support</p>
                  </a>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 10.5V12.5C10 12.7652 9.89464 13.0196 9.70711 13.2071C9.51957 13.3946 9.26522 13.5 9 13.5H2C1.73478 13.5 1.48043 13.3946 1.29289 13.2071C1.10536 13.0196 1 12.7652 1 12.5V1.5C1 1.23478 1.10536 0.98043 1.29289 0.792893C1.48043 0.605357 1.73478 0.5 2 0.5H9C9.26522 0.5 9.51957 0.605357 9.70711 0.792893C9.89464 0.98043 10 1.23478 10 1.5V3.5"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 7H6"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5L6 7L8 9"
                      stroke="#4E637B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <a
                    href={`/logout`}
                    className="text-[#4E637B] text-[14px] font-medium hover:text-white"
                  >
                    <p>Discord</p>
                  </a>
                </div>
              </div>
            </div>
            // <div className="p-[20px]">
            //   <div
            //     className="flex items-center gap-2 cursor-pointer"
            //     onClick={() => {
            //       setMore(!more);
            //     }}
            //   >
            //     <ImageAvatar image={profile?.image} showCrossIcon={false} size={40} />
            //     <div>
            //       <div className="text-white relative">
            //         {profile?.full_name}
            //         <div className="absolute !-right-[15px] !-top-[20px] sub-plan">
            //           {subscription.subscription_plan
            //             ? subscription.subscription_plan.name
            //             : 'Free'}
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   {more && (
            //     <div
            //       className="absolute  top-[-120px] w-[100%] min-w-[230px]"
            //       style={{
            //         background: 'var(--Neutral-8, #222831)',
            //         borderRadius: '12px',
            //         borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
            //       }}
            //     >
            //       <div
            //         className="p-[16px]"
            //         style={{
            //           borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
            //         }}
            //       >
            //         <div className="flex gap-4 items-center">
            //           <img className="w-[16px] h-[16px]" src="/icons/add-user.svg" alt="" />
            //           <Link to={`/profile-settings`}>
            //             <p>Profile Setting</p>
            //           </Link>
            //         </div>
            //       </div>

            //       <div className="p-[16px] items-center">
            //         <div className="flex gap-4 items-center">
            //           <img className="w-[16px] h-[16px]" src="/icons/setting.svg" alt="" />
            //           <a href={`/logout`}>
            //             <p>Logout</p>
            //           </a>
            //         </div>
            //       </div>
            //     </div>
            //   )}
            // </div>
          )}
        </div>
        {/* End of sidebar content */}
      </div>
      {/* sidebar end */}

      {/* Main Content and Input fields */}
      <div className="pl-64 main-content custom-scroll">{children}</div>
      {/* End of Main Content and Input fields */}
    </div>
  );
};

const SidebarMessage = ({ messages }: any) => {
  const { projectUid } = useParams();
  if (!messages) return null;
  return (
    <div className="flex justify-center items-start flex-col mt-6 pl-4">
      <div className="flex gap-4  items-center mt-6">
        <a href={`/agents/${projectUid}/chat/`}>
          <p className="CommunityText">Chat History</p>
        </a>
      </div>

      <div className="flex mt-4 gap-1">
        <div style={{ background: 'var(--Neutral-8, #191f28)', width: '1px' }}></div>
        <div className="scrollable">
          {messages.map((message: any) => {
            return (
              <Link key={`message-${message.id}`} to={`/agents/${projectUid}/chat/${message.id}`}>
                <p className="projects">{message.question}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AgentSideBar = ({ aiAgents, searchAgents }: any) => {
  return (
    <div className=" flex justify-center items-start flex-col mt-2 pl-4">
      <div className="flex gap-4  items-center mt-6">
        <Link to={'/agents'}>
          <p className="text-[13px] font-semibold text-[#637B94] pl-3 uppercase">Simple Agents</p>
        </Link>
      </div>
      <div className="mt-2 mb-8 w-full pr-4">
        {searchAgents.map((agent: any) => {
          return (
            <Link
              key={`agent-${agent.uid}`}
              to={`/agents/${agent.uid}/chat`}
              className="flex gap-3 items-center mb-1.5 py-3 px-[10px] rounded-lg hover:bg-[#222831]"
            >
              <img
                className=""
                width="24"
                height="24"
                src={getFilePath(agent?.image?.file_path, '/user/User.png')}
                alt=""
              />
              <p key={agent.uid} className="text-[#F6F7F9] text-base font-semibold line-clamp-1">
                {agent.name}
              </p>
            </Link>
          );
        })}
      </div>

      <div className="flex gap-4  items-center mt-6">
        <Link to={'/agents'}>
          <p className="text-[13px] font-semibold text-[#637B94] pl-3 uppercase">Advance Agents</p>
        </Link>
      </div>

      <div className="mt-4 w-full pr-4 pb-[60px]">
        {aiAgents.map((agent: any) => {
          return (
            <Link
              key={`agent-${agent.uid}`}
              to={`/agents/${agent.uid}/chat`}
              className="flex gap-3 items-center mb-1.5 py-3 px-[10px] rounded-lg hover:bg-[#222831]"
            >
              <img
                className=""
                width="24"
                height="24"
                src={getFilePath(agent?.image?.file_path, '/user/User.png')}
                alt=""
              />
              <p key={agent.uid} className="text-[#F6F7F9] text-base font-semibold line-clamp-1">
                {agent.name}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
