import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useQuery } from 'core/hooks';
import { RootStateType } from 'store/store';
import { findCustomerUser } from 'store/reducers/customerChatSlice';

import { LeftMessageItem } from './LeftMessageItem';
import { RightMessageItem } from './RightMessageItem';
import MessageBottomSearch from './MessageBottomSearch';

import './MessageItem.scss';

export const MessageItem = () => {
  const query = useQuery();
  const [input, setInput] = useState<string>('');
  const activeCustomerId = query.get('cId');

  const customerUser = useSelector((state: RootStateType) =>
    findCustomerUser(state.customerChat.customers, Number(activeCustomerId))
  );

  const messages = customerUser?.messages || [];

  if (!messages.length) {
    return <NoMessageBox />;
  }

  return (
    <>
      <MessageBottomSearch
        input={input}
        setInput={setInput}
        activeCustomerId={activeCustomerId}
        isManualMessage={customerUser?.is_manual_message}
      />
      <div className="w-3/4 mx-auto flex-col-reverse flex messages pb-[150px]">
        {messages.map((message) => {
          if (message.sender_type == 'CUS') {
            return (
              <LeftMessageItem
                key={`message-obj-${message.id}`}
                setBotInput={setInput}
                description={message.content}
                meta={true}
              />
            );
          }
          return (
            <RightMessageItem key={`message-obj-${message.id}`} message={message} meta={true} />
          );
        })}
      </div>
    </>
  );
};

const NoMessageBox = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="rounded-lg shadow-lg p-8 max-w-md mx-auto text-center absolute left-1/2	top-1/2 -translate-y-1/2 -translate-x-1/2">
        <div className="mb-4">
          <img className="max-w-md mx-auto text-center" src="/message/laptop-with-chat.svg" />
        </div>
        <h2 className="text-2xl font-semibold mb-2">Start a Conversation</h2>
        <p className="text-gray-400">Select a customer to start the conversation</p>
      </div>
    </div>
  );
};
