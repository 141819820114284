import './Login/Login.css';

import { clsx } from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '../apis/Auth';
import Background from '../components/AuthBackground/AuthBackground';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import GoogleAuthButton from '../components/Oauth/GoogleOauth';
import { setTokens } from '../core/helpers/token';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

const LoginPage = () => {
  const navigate = useNavigate();
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const [loginError, setLoginError] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formErrors: any = {};
    let hasErrors = false;

    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
      hasErrors = true;
    }

    if (!formData.password.trim()) {
      formErrors.password = 'Password is required';
      hasErrors = true;
    }

    if (!hasErrors) {
      const payload = {
        email: formData.email,
        password: formData.password
      };

      setIsLoading(true);
      const data = await Auth.loginUser(payload);
      setIsLoading(false);

      if (data) {
        setLoginError(false);
        setTokens(data.access_token, data.refresh_token);
        navigate('/');
      } else {
        setLoginError(true);
      }
    }
    setErrors(formErrors);
  };
  return (
    <div className="relative">
      <Background imageUrl="/auth/authback.png" />

      <div className="main-container h-screen">
        <div className="mt-[20px] align-center flex justify-center">
          <img src="/brand-logo/logo.png" alt="" />
        </div>
        <div className="mt-[20px] align-center flex justify-center">
          <h3 className="AuthHeader Manrope text-white">Log In</h3>
        </div>

        <div className="flex justify-center mt-4">
          <div className="AuthBody flex justify-center">
            <div className="w-[400px]">
              {/* form section */}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="email"
                      className="Manrope text-sm font-semibold mb-1 InputLabel "
                    >
                      Email
                    </label>
                  </div>
                  <input
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    id="email"
                    name="email"
                    className={clsx({
                      'transparent-input w-full border mt-[5px] text-white Input ': true,
                      ErrorInput: loginError
                    })}
                  />
                  <div className="flex flex-start">
                    {errors.email && (
                      <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="password"
                      className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
                    >
                      Password:
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      type={passwordVisibility ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="w-full transparent-input border mt-[5px]  text-white Input"
                    />
                    <img
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                      className="absolute eyeIcon pointer "
                      src="/auth/Icon.png"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-start">
                    {errors.password && (
                      <div className="text-red-500 text-sm mt-1">{errors.password}</div>
                    )}
                  </div>
                  {loginError && (
                    <div className="flex justify-between mt-4 items-center">
                      <p className="text-red-500 error-text">
                        Username or password you entered is incorrect!
                      </p>
                      <span
                        style={{ color: 'white' }}
                        className="error-text text-white cursor-pointer"
                        onClick={() => setIsForgotPasswordOpen(true)}
                      >
                        Forgot password?
                      </span>
                    </div>
                  )}
                </div>
                <SpinnerLoader loading={isLoading}>
                  <button type="submit" className="submitBtn w-full mt-8">
                    Login
                  </button>
                </SpinnerLoader>
              </form>
              <div className="flex justify-between mt-4 items-center gap-[10px] ">
                <div className="splitter"></div>
                <p className="continueText">or Continue with</p>
                <div className="splitter"></div>
              </div>

              <GoogleAuthButton />
              <div className="mt-4">
                <p className="createAccountText">
                  Don&apos;t have an account?
                  <a href="/register">
                    <span className="loginText">Register</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ForgotPasswordModal
        isOpen={isForgotPasswordOpen}
        onClose={() => setIsForgotPasswordOpen(false)}
      />
    </div>
  );
};

export default LoginPage;
