import React from 'react';
import { ICustomerMessage } from 'types/customerChat';
import IngestFiles from './IngestFiles';

interface RightMessageItemProps {
  message: ICustomerMessage;
  meta: boolean;
}

export const RightMessageItem = ({ message, meta }: RightMessageItemProps) => {
  const senderName = message.sender_type == 'BOT' ? 'Bot' : message.user?.full_name;
  return (
    <div className="mb-12 message-item pr-6 ml-auto">
      <div>
        <p className="text-[#F6F7F9] text-[16px] p-4 mb-4 bg-[#222831] rounded-[10px] rounded-tr-none right__message-text">
          {message.content}
        </p>

        {meta && (
          <div className="flex items-center gap-2 float-right	">
            <div className="text-[12px]">
              <p>{senderName}</p>
            </div>
            {/* <div className="cursor-pointer">
              <img src="/message/reload-image.svg" width="20" alt="" />
            </div>
            <div className="cursor-pointer">
              <img src="/message/like-image.svg" width="20" alt="" />
            </div>
            <div className="cursor-pointer">
              <img src="/message/dislike-image.svg" width="20" alt="" />
            </div> */}
          </div>
        )}
        <IngestFiles ingests={message?.ingests} />
      </div>
    </div>
  );
};
