import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { UserProfile } from './apis/Profile';
import { GOOGLE_CLIENT_ID } from 'core/constants';
import { getRefreshToken, removeTokens } from 'core/helpers/token';
import { setProfile } from './store/reducers/loginSlice';

import ChatNew from './pages/Chat/ChatNew';
import FallbackPage from './pages/Fallback';
import Home from './pages/Home';
import LoginPage from './pages/Login';
import Onboarding from './pages/Onboarding/Onboarding';
import ProfileSettings from './pages/ProfileSettings/ProfileSettings';
import Projects from './pages/Projects/Projects';
import FileUploadModal from './pages/ProjectSettings/ProjectSetting';
import RegistrationPage from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings/Settings';
import PaymentCanceled from './pages/Subscriptions/PaymentCanceled';
import PaymentSuccess from './pages/Subscriptions/PaymentSuccess';
import Subscription from './pages/Subscriptions/Subscription';
import Message from 'pages/Message/Message';
import WebSocketComponent from 'websocket/WebSocketComponent';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const WrapperComponent = ({ children }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const accessToken = getRefreshToken();

  const profile = useSelector((state: any) => state.login.profile);

  const loadUserProfile = async () => {
    const data = await UserProfile.get();
    if (data !== null) {
      dispatch(setProfile(data));
      if (!data.has_subscription) {
        navigate('/subscriptions/packages');
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      loadUserProfile();
    } else {
      if (location.pathname === '/register') navigate('/register');
      else navigate('/login');
    }
  }, [accessToken]);

  useEffect(() => {
    if (profile && !profile?.has_subscription) {
      if (location.pathname !== '/subscriptions/packages') {
        navigate('/subscriptions/packages');
      }
    }
  }, [profile, location]);

  return (
    <>
      <WebSocketComponent profile={profile} />
      {children}
    </>
  );
};

const LogoutHandle = () => {
  const navigate = useNavigate();
  useEffect(() => {
    removeTokens();
    navigate('/login');
  }, []);

  return <h1>Login out...</h1>;
};
export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <WrapperComponent>
        <Home />
      </WrapperComponent>
    )
  },
  {
    path: '/subscriptions/success',
    element: (
      <WrapperComponent>
        <PaymentSuccess />
      </WrapperComponent>
    )
  },
  {
    path: '/subscriptions/canceled',
    element: (
      <WrapperComponent>
        <PaymentCanceled />
      </WrapperComponent>
    )
  },
  {
    path: '/subscriptions/packages',
    element: (
      <WrapperComponent>
        <Subscription />
      </WrapperComponent>
    )
  },
  {
    path: '/login',
    element: (
      <WrapperComponent>
        <LoginPage />
      </WrapperComponent>
    )
  },
  {
    path: '/register',
    element: (
      <WrapperComponent>
        <RegistrationPage />
      </WrapperComponent>
    )
  },
  {
    path: '/logout',
    element: <LogoutHandle />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/agents',
    element: (
      <WrapperComponent>
        <Projects />
      </WrapperComponent>
    )
  },
  {
    path: '/project-settings',
    element: (
      <WrapperComponent>
        <FileUploadModal />
      </WrapperComponent>
    )
  },
  {
    path: '/onboarding',
    element: (
      <WrapperComponent>
        <Onboarding />
      </WrapperComponent>
    )
  },
  // {
  //   path: '/agents/:projectUid/chat',
  //   element: (
  //     <WrapperComponent>
  //       <Chat />
  //     </WrapperComponent>
  //   )
  // },
  {
    path: '/agents/:projectUid/chat',
    element: (
      <WrapperComponent>
        <ChatNew />
      </WrapperComponent>
    )
  },
  {
    path: '/agents/:projectUid/chat/:messageId',
    element: (
      <WrapperComponent>
        <ChatNew />
      </WrapperComponent>
    )
  },
  {
    path: '/agents/:projectUid/settings',
    element: (
      <WrapperComponent>
        <Settings />
      </WrapperComponent>
    )
  },
  {
    path: '/profile-settings',
    element: (
      <WrapperComponent>
        <ProfileSettings />
      </WrapperComponent>
    )
  },
  {
    path: '/agents/:projectUid/chat-widget/:chatWidgetId/customer-chats',
    element: (
      <WrapperComponent>
        <Message />
      </WrapperComponent>
    )
  },

  {
    path: '*',
    element: <FallbackPage />
  }
]);

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
