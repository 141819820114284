import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';

import { Ingest } from 'apis/Ingest';
import { ingestConfig } from 'core/helpers/ingest';
import IngestUploadBtn from '../IngestUploadBtn';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

interface IUrl {
  id: number;
  name: string;
  isChecked: boolean;
}

type IFormInput = {
  name: string;
  url: string;
};

export default function IngestUrl({ onClose, handleOnSubmit }: any) {
  const { projectUid } = useParams();

  const [items, setItems] = useState<IUrl[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();

  const mainUrl = useWatch({ control, name: 'url' });

  const handleSelectAll = (event: any) => {
    const checked = event.target.checked;
    setItems(items.map((item) => ({ ...item, isChecked: checked })));
  };

  const handleItemChange = (event: any, id: number) => {
    const checked = event.target.checked;
    setItems(items.map((item) => (item.id === id ? { ...item, isChecked: checked } : item)));
  };

  const loadUrls = async (event: any) => {
    event.preventDefault();

    if (!mainUrl) {
      alert('URL is not valid');
      return null;
    }

    setIsLoading(true);
    const data = await Ingest.getPageUrls(projectUid, { url: encodeURI(mainUrl) });
    setIsLoading(false);

    if (data) {
      const urlItems = data.urls.map((url: string, idx: number) => {
        return {
          id: idx,
          name: url,
          isChecked: true
        };
      });

      setItems(urlItems);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const urls = items.reduce((list: string[], item: IUrl) => {
      if (item.isChecked) {
        list.push(item.name);
      }
      return list;
    }, []);

    const payload = {
      reader_type: 'URL',
      url: {
        name: data.name,
        link: data.url,
        urls: urls
      },
      config: ingestConfig
    };

    handleOnSubmit(payload);
  };

  if (!projectUid) return null;

  const areAllSelected = items.every((item) => item.isChecked);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Url</div>
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
            Name of ingest
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('name', { required: true })}
          />
        </div>
        {errors.name && <span className="text-red-400">This field is required</span>}
      </div>
      <SpinnerLoader loading={isLoading}>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]">
              URL
            </label>
          </div>
          <div className="relative flex gap-2">
            <input
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('url', { required: true })}
              type="url"
            />

            <button
              onClick={loadUrls}
              className="w-[120px] mt-[5px] items-center justify-center text-center p-1 rounded-[10px] bg-slate-600 text-white"
            >
              Load URLs
            </button>
          </div>
          {errors.url && <span className="text-red-400">This field is required</span>}
        </div>
      </SpinnerLoader>

      {items.length > 0 && (
        <div className="mt-4">
          <h3 className="mb-4 font-semibold text-gray-900 dark:text-white">URLs</h3>
          <ul className="max-h-[300px] common-scrollbar-thin text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <OptionItem onChange={handleSelectAll} isChecked={areAllSelected} name={'Select All'} />
            {items.map((item) => (
              <OptionItem
                key={`urls-${item.id}`}
                onChange={(event: any) => handleItemChange(event, item.id)}
                isChecked={item.isChecked}
                name={item.name}
              />
            ))}
          </ul>
        </div>
      )}
      <IngestUploadBtn onClose={onClose} isDisabled={isLoading} />
    </form>
  );
}

const OptionItem = ({ onChange, isChecked, name }: any) => {
  return (
    <li className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
      <div className="flex items-center ps-3">
        <input
          type="checkbox"
          onChange={onChange}
          checked={isChecked}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
        />
        <label
          htmlFor="vue-checkbox"
          className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {name}
        </label>
      </div>
    </li>
  );
};
