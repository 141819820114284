import { CustomerChatAPI } from 'apis/customerChat';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function MessageBottomSearch({ activeCustomerId, isManualMessage, input, setInput }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useDispatch();
  const { projectUid, chatWidgetId } = useParams();

  if (!projectUid || !chatWidgetId || !activeCustomerId) return null;

  const handleSend = async () => {
    const inputContent = input.trim();

    if (inputContent) {
      setIsLoading(true);
      const payload = { content: inputContent };
      await CustomerChatAPI.sendMessage(
        dispatch,
        projectUid,
        chatWidgetId,
        activeCustomerId,
        payload
      );
      setIsLoading(false);
      setInput('');
    }
  };
  const handleGenerateMessage = async () => {
    const inputContent = input.trim();

    if (inputContent) {
      setIsLoading(true);
      const payload = { content: inputContent };
      const generatedMessage = await CustomerChatAPI.generateMessage(
        projectUid,
        chatWidgetId,
        activeCustomerId,
        payload
      );

      if (generatedMessage) {
        setInput(generatedMessage.content);
        setIsExpanded(true);
      } else {
        setInput('');
      }

      setIsLoading(false);
    }
  };

  const handelManualControlChange = async () => {
    const payload = {
      is_manual_message: !isManualMessage
    };
    CustomerChatAPI.updateCustomerUser(
      dispatch,
      projectUid,
      chatWidgetId,
      activeCustomerId,
      payload
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput(e.target.value);
    if (e.target.value.length > 0 && !isExpanded) {
      setIsExpanded(true);
    }
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    if (input.length === 0) {
      setIsExpanded(false);
    }
  };

  return (
    <div className="fixed bottom-0 left-[296px] right-0 px-10 overflow-hidden bg-[#121821] max-w-4xl mx-auto z-10 pt-[16px]">
      <div className="flex justify-center">
        {/* <button className="flex items-center bg-[#222831] rounded-[4px] p-[7px] m-[6px] cursor-pointer">
          <span className="text-[#F6F7F9] text-[14px]">{controlText}</span>
        </button> */}

        <div className="flex items-center gap-[8px] toggle-container mb-9">
          <label
            className="toggle-label text-[#F6F7F9] text-[14px]"
            id="toggle-label"
            htmlFor="switcher"
          >
            Manual
          </label>
          <div className="switch-btn">
            <input
              type="checkbox"
              id="switcher"
              className="check"
              checked={isManualMessage}
              onChange={handelManualControlChange}
            />
            <b className="switch-circle switch"></b>
          </div>
        </div>
      </div>
      <div className="flex items-center min-h-[68px] bg-[#191F28] border border-[#222831] rounded-lg relative top-[-30px]">
        {isExpanded ? (
          <textarea
            placeholder="Reply message..."
            className="flex-1 px-4 py-2 text-white bg-transparent focus:outline-none resize-none mb-2"
            value={input}
            onChange={handleInputChange}
            onBlur={handleBlur}
            rows={3}
            autoFocus
          />
        ) : (
          <input
            type="text"
            placeholder="Reply message..."
            className="flex-1 px-4 py-2 text-white bg-transparent focus:outline-none"
            value={input}
            onChange={handleInputChange}
            onFocus={handleFocus}
          />
        )}
        <button
          className="flex items-center gap-2 bg-[#222831] rounded-[4px] py-[7px] px-[16px] mr-[8px] cursor-pointer"
          onClick={handleGenerateMessage}
          disabled={isLoading}
        >
          <img width="16" height="16" src="/message/response-icon.svg.svg" />
          <span className="text-[#F6F7F9] text-[14px]">Generate Response</span>
        </button>
        <button
          className="w-[36px] h-[36px] flex items-center justify-center bg-[#222831] rounded-[4px] mr-[18px] focus:outline-none"
          onClick={handleSend}
          disabled={isLoading}
        >
          <img width="16" height="16" src="/message/mail-send-icon.svg" />
        </button>
      </div>
    </div>
  );
}

export default MessageBottomSearch;
