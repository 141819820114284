import React, { useState } from 'react';

import './SubscriptionCard.css';

import { Subscription } from '../../apis/subscription';
import SpinnerLoader from 'components/Utility/SpinnerLoader';
interface SubscriptionCardProps {
  packageName: string;
  description: string;
  price: number;
  plan_type: string;
  benefits: any[];
  id: number;
}

export const SubscriptionCard = (props: SubscriptionCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const buyPackage = async (plan_id: number, auto_renew = true) => {
    if (props.plan_type === 'FREE') {
      setIsLoading(true);
      const data = await Subscription.subscribe({ plan_id, auto_renew });
      setIsLoading(false);
      if (data) {
        setIsOpen(false);
        window.location.href = '/agents';
      }
    } else {
      setIsLoading(true);
      const data = await Subscription.createSubscriptionSession({ plan_id });
      setIsLoading(false);
      if (data) {
        window.location = data?.url;
      }
    }
  };

  const price = props.price / 100;

  return (
    <div className="cardContainer ">
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center h-screen">
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="bg-white w-full max-w-lg mx-auto p-4 rounded-lg shadow-xl">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-gray-800">Subscription</h2>
                  <button onClick={closeModal} className="text-red-600 hover:text-red-700">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="text-gray-700">
                  {/* Modal content goes here */}
                  Do you want to subscribe <span className="font-bold">
                    {props.packageName}
                  </span>{' '}
                  package?
                </div>
                <p className="text-gray-700 font-bold">Price: {price} USD</p>
                <div className="flex justify-end">
                  <SpinnerLoader loading={isLoading}>
                    <button
                      onClick={() => {
                        buyPackage(props.id);
                      }}
                      className="bg-blue-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 transition duration-150 ease-in-out"
                    >
                      Buy
                    </button>
                  </SpinnerLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <p className="packageName">{props.packageName}</p>
      <p className="description mt-2">{props.description}</p>
      <p className="packagePrice mt-3">{price} USD</p>
      <div className="mt-6 flex gap-3 flex-col">
        {props.benefits.map((benefit) => {
          return (
            <div key={benefit.num} className="flex gap-1 items-center">
              <img className="w-[16px] h-[16px]" src="/general/ticksolid.png" alt="" />
              <p className="benefitsText">
                {benefit.num} {benefit.msg}
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex mt-6">
        <button onClick={openModal} className="buyButton flex-1">
          Enroll Now
        </button>
      </div>
    </div>
  );
};
