import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Subscription } from '../../apis/subscription';
import { Subscription as SubscriptionAPI } from '../../apis/subscription';
import { setSubscription } from '../../store/reducers/loginSlice';
import { tokenToMessage, tokenToPage } from 'core/helpers/token';

function PackageInformation() {
  const dispatch = useDispatch();

  const subscription = useSelector((state: any) => state.login.subscription);

  const loadSubscriptionDetails = async () => {
    const data = await SubscriptionAPI.subscriptionDetails();

    dispatch(setSubscription(data));
  };

  useEffect(() => {
    loadSubscriptionDetails();
  }, []);

  const handleSubscriptionCancel = async (e: any) => {
    e.preventDefault();

    const isConfirm = window.confirm('Are you sure that you want to cancel the subscription?');
    if (isConfirm === true) {
      const data = await Subscription.cancelSubscription({});
      if (data) {
        window.location.reload();
      }
    }
  };

  const hasActiveSubscription = !!subscription?.subscription_plan;
  let isCancelable = !subscription?.is_canceled;

  if (!hasActiveSubscription) isCancelable = false;
  if (subscription?.subscription_plan?.plan_type === 'FREE') isCancelable = false;

  return (
    <div className="p-4">
      <h2 className="pb-8 text-[24px] font-semibold">
        Package Information <span>{!hasActiveSubscription && '(No active subscription)'}</span>
      </h2>
      <div className="w-[720px]">
        {hasActiveSubscription && (
          <div className="gap-[10px] mb-8">
            <div className="mb-3 w-full">
              <label className="block text-[#8297AE] text-[13px]">Active Package</label>
              <p className="bg-transparent text-[#F6F7F9] text-base py-2 outline-none w-full">
                {subscription?.subscription_plan?.name}
                <span>{hasActiveSubscription && subscription?.is_canceled && ' (Canceled)'}</span>
              </p>
            </div>
            <div className="mb-3 w-full">
              <label className="block text-[#8297AE] text-[13px]">Current month usage</label>
              <p className="bg-transparent text-[#F6F7F9] text-base py-2 outline-none w-full">
                {tokenToMessage(subscription?.total_output_token)} of{' '}
                {tokenToMessage(subscription?.total_allowed_token)} Message Credit Used
              </p>
              <p className="bg-transparent text-[#F6F7F9] text-base py-2 outline-none w-full">
                {tokenToPage(subscription?.total_uploaded_token)} of{' '}
                {tokenToPage(subscription?.per_project_allowed_ingest_token)} Page Uploaded
              </p>
            </div>
          </div>
        )}
        <div className="flex item-center gap-[16px]">
          <Link
            to={'/subscriptions/packages'}
            className="flex items-center gap-[8px] bg-gradient-to-r from-sky-300 to-blue-500 py-2.5 px-4 rounded-[8px]"
          >
            <img src="/icons/gpt-icon.svg" alt="Upgrade Subscription" />
            Upgrade Plan
          </Link>
          {hasActiveSubscription && (
            <button
              className={`flex items-center gap-[8px] bg-gradient-to-r bg-[#a22f2f] py-2.5 px-4 rounded-[8px] ${
                !isCancelable && 'cursor-not-allowed'
              }`}
              onClick={handleSubscriptionCancel}
              disabled={!isCancelable}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PackageInformation;
