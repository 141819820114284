import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Agents } from '../../apis/Agents';
import { getFilePath } from '../../core/helpers';

import AgentUpdateModal from './AgentUpdateModal';
import { tokenToPage } from 'core/helpers/token';

function Information({ agent }: any) {
  const navigate = useNavigate();

  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleDelete = async (e: any) => {
    e.preventDefault();
    const isConfirm = window.confirm('Are you sure that you want to delete the agent?');
    if (isConfirm === true) {
      const data = await Agents.deleteAgents(agent.uid);

      if (data) {
        navigate('/agents');
      }
    }
  };
  if (!agent) return null;

  return (
    <div className="p-8 border-[1px] border-solid border-[#222831] rounded-[16px] w-[720px]">
      <img src={getFilePath(agent.image?.file_path)} alt="agent" className="w-12 h-12 mb-8" />
      <div className="flex gap-[10px] mb-3">
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Agent Name</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.name}</h4>
        </div>
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">AI Model</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.llm}</h4>
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Agent Type</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.type}</h4>
        </div>
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Embed Model</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.embeddings}</h4>
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Cutoff Score</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.score}</h4>
        </div>
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Top K</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.k}</h4>
        </div>
      </div>
      <div className="flex gap-[10px]">
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Chunk Size</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.chunk_size}</h4>
        </div>
        <div className="mb-3 w-1/2">
          <label className="block text-[#8297AE] text-[13px]">Chunk Overlap</label>
          <h4 className="text-[#F6F7F9] text-base py-2">{agent.chunk_overlap}</h4>
        </div>
      </div>
      <div className="mb-8">
        <div className="mb-3">
          <label className="block text-[#8297AE] text-[13px] pb-2">Uploaded Ingest</label>
          <p>
            {tokenToPage(agent.total_uploaded_token)} of{' '}
            {tokenToPage(agent.per_project_allowed_ingest_token)} Page Uploaded
          </p>
        </div>
      </div>
      <div className="mb-8">
        <div className="mb-3">
          <label className="block text-[#8297AE] text-[13px] pb-2">System Prompt</label>
          <p>{agent.system}</p>
        </div>
      </div>
      <div className="mb-8">
        <div className="mb-3">
          <label className="block text-[#8297AE] text-[13px] pb-2">Description</label>
          <p>{agent.description}</p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="flex items-center gap-[8px] bg-[#323C48] py-2.5 px-4 rounded-[8px]"
          onClick={() => setIsEditOpen(true)}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.75 13.5625H2.25C1.97344 13.5625 1.75 13.7859 1.75 14.0625V14.625C1.75 14.6938 1.80625 14.75 1.875 14.75H14.125C14.1938 14.75 14.25 14.6938 14.25 14.625V14.0625C14.25 13.7859 14.0266 13.5625 13.75 13.5625ZM4.02656 12.25C4.05781 12.25 4.08906 12.2469 4.12031 12.2422L6.74844 11.7812C6.77969 11.775 6.80938 11.7609 6.83125 11.7375L13.4547 5.11406C13.4692 5.09961 13.4807 5.08244 13.4885 5.06354C13.4963 5.04463 13.5004 5.02437 13.5004 5.00391C13.5004 4.98344 13.4963 4.96318 13.4885 4.94428C13.4807 4.92538 13.4692 4.90821 13.4547 4.89375L10.8578 2.29531C10.8281 2.26563 10.7891 2.25 10.7469 2.25C10.7047 2.25 10.6656 2.26563 10.6359 2.29531L4.0125 8.91875C3.98906 8.94219 3.975 8.97031 3.96875 9.00156L3.50781 11.6297C3.49261 11.7134 3.49804 11.7995 3.52364 11.8807C3.54923 11.9618 3.59421 12.0355 3.65469 12.0953C3.75781 12.1953 3.8875 12.25 4.02656 12.25Z"
              fill="#8297AE"
            />
          </svg>
          Edit
        </button>
        <button className="text-[#FF564B] text-[16px]" onClick={handleDelete}>
          Delete Agent
        </button>
      </div>
      <AgentUpdateModal isOpen={isEditOpen} agent={agent} onClose={() => setIsEditOpen(false)} />
    </div>
  );
}

export default Information;
