import React, { useState } from 'react';

import { Agents } from '../../../apis/Agents';
import { IImageType } from '../../../types';
import UpdateAgentUserPermission from '../../AddUser/UpdateAgentUserPermission';
import ImageAvatar from '../../ImageAvatar';

interface userItemProps {
  projectUid: string;
  image: IImageType | null;
  user_name: string;
  permission_type: string;
  creator_name: string;
  date: Date;
  permissionId: number;
}
function UsersItem({
  projectUid,
  permissionId,
  image,
  user_name,
  permission_type,
  creator_name,
  date
}: userItemProps) {
  const dateStr = date?.toString().split('T')[0];
  const [isUserAddOpen, setIsUserAddOpen] = useState(false);

  const onUserRemove = async () => {
    const isConfirm = window.confirm('Are you sure want to remove the user?');

    if (isConfirm) {
      const data = await Agents.deleteAgentUsers(projectUid, permissionId);
      if (data) {
        window.location.reload();
      }
    }
  };

  return (
    <tr>
      <td className="pt-8 pb-1">
        <div className="flex items-center gap-4">
          <ImageAvatar image={image} size={50} showCrossIcon={false} />
          <div>
            <span className="text-[#F6F7F9] text-[16px] font-normal">{user_name}</span>
          </div>
        </div>
      </td>
      <td className="pt-8 pb-1 text-left">
        <span className="text-[#F6F7F9] text-[16px] font-normal">{permission_type}</span>
      </td>
      <td className="pt-8 pb-1 text-left">
        <span className="text-[#F6F7F9] text-[16px] font-normal">{creator_name}</span>
      </td>
      <td className="pt-8 pb-1 text-center">
        <span className="text-[#F6F7F9] text-[16px] font-normal">{dateStr}</span>
      </td>
      <td className="pt-8 pb-1">
        <div className="flex items-cneter justify-center gap-3">
          <button onClick={() => setIsUserAddOpen(true)}>
            <img src="/icons/edit-icon.svg" alt="edit" />
          </button>
          <button onClick={onUserRemove}>
            <img src="/icons/delete-icon-gray.svg" alt="delete" />
          </button>
        </div>
      </td>
      <UpdateAgentUserPermission
        permissionId={permissionId}
        projectUid={projectUid}
        isOpen={isUserAddOpen}
        permissionType={permission_type}
        onClose={() => setIsUserAddOpen(false)}
      />
    </tr>
  );
}

export default UsersItem;
