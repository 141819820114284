import React, { useEffect } from 'react';
import { useForm, SubmitHandler, useWatch, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { ReaderEnum, ingestConfig } from 'core/helpers/ingest';

import IngestUploadBtn from '../IngestUploadBtn';

type IFormInput = {
  secret_id: string;
  chanel_id: string;
  chanel_name: string;
  chanel_link: string;
  earliest_date: Date | null;
  latest_date: Date | null;
};

export default function IngestSlack({ handleOnSubmit, onClose, secretKeys }: any) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<IFormInput>();

  useEffect(() => {
    if (secretKeys?.length) {
      setValue('secret_id', secretKeys[0].id, { shouldDirty: true });
    }
  }, [secretKeys]);

  const handleChange = (name: any, dateChange: any) =>
    setValue(name, dateChange, { shouldDirty: true });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const payload = {
      reader_type: ReaderEnum.SLACK,
      secret_id: data.secret_id,
      slack: {
        chanel_ids: [data.chanel_id],
        chanel_name: data.chanel_name,
        chanel_link: data.chanel_link,
        earliest_date: data.earliest_date,
        latest_date: data.latest_date
      },
      config: ingestConfig
    };
    handleOnSubmit(payload);
  };

  const earliest_date = useWatch({ control, name: 'earliest_date' });
  const latest_date = useWatch({ control, name: 'latest_date' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-3 pb-7">
        <div className="-mt-1">Slack</div>
      </div>
      <div className="mb-4">
        <label className="text-[13px] block font-normal mb-2 leading-4">Slack Bot Token</label>
        <select
          {...register('secret_id', { required: true })}
          className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
        >
          {secretKeys.map((secretKey: any) => {
            return (
              <option
                key={`secret-key-option-${secretKey.id}`}
                value={secretKey.id}
                className="custom-option"
              >
                {secretKey.name}
              </option>
            );
          })}
        </select>
        {errors.secret_id && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Channel ID
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('chanel_id', { required: true })}
          />
        </div>
        {errors.chanel_id && <span className="text-red-400">This field is required</span>}
      </div>

      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Channel Name
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('chanel_name', { required: true })}
          />
        </div>
        {errors.chanel_id && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Channel Link
          </label>
        </div>
        <div className="relative">
          <input
            className="w-full bg-slate-700 border mt-[5px]  text-white Input"
            {...register('chanel_link', { required: true })}
          />
        </div>
        {errors.chanel_id && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Earliest Message Date
          </label>
        </div>
        <div className="relative">
          <Controller
            name="earliest_date"
            control={control}
            render={() => (
              <DatePicker
                wrapperClassName="w-full"
                className="w-full bg-slate-700 border mt-[5px]  text-white Input"
                placeholderText="Select date"
                selected={earliest_date}
                maxDate={new Date()}
                onChange={(date) => handleChange('earliest_date', date)}
              />
            )}
          />
        </div>
        {errors.earliest_date && <span className="text-red-400">This field is required</span>}
      </div>
      <div className="md-4">
        <div className="flex flex-start">
          <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
            Last Message Date
          </label>
        </div>
        <div className="relative">
          <Controller
            name="latest_date"
            control={control}
            render={() => (
              <DatePicker
                wrapperClassName="w-full"
                className="w-full bg-slate-700 border mt-[5px]  text-white Input"
                placeholderText="Select date"
                selected={latest_date}
                // TODO: Add validation between start date and end date
                onChange={(date) => handleChange('latest_date', date)}
              />
            )}
          />
        </div>
        {errors.latest_date && <span className="text-red-400">This field is required</span>}
      </div>
      <IngestUploadBtn onClose={onClose} />
    </form>
  );
}
