import React from 'react';

interface LeftMessageItemProps {
  description: string | null;
  meta: boolean;
  setBotInput: (v: string) => void;
}

export const LeftMessageItem = ({ description, meta, setBotInput }: LeftMessageItemProps) => {
  const onClickGpt = () => {
    setBotInput(description || '');
  };
  return (
    <div className="message-item mb-6">
      {/* <div className="w-[32px]">
        <img width="32" src={image} alt="" />
      </div> */}
      <div className="w-full">
        <p className="text-[#F6F7F9] text-[16px] mb-3">{description}</p>
        {meta && (
          <div className="flex items-center gap-2">
            {/* <div className="cursor-pointer">
              <img src="/message/reload-image.svg" width="20" alt="" />
            </div>
            <div className="cursor-pointer">
              <img src="/message/like-image.svg" width="20" alt="" />
            </div>
            <div className="cursor-pointer">
              <img src="/message/dislike-image.svg" width="20" alt="" />
            </div> */}
            <div className="cursor-pointer text-white" onClick={onClickGpt}>
              <img src="/icons/copy-icon.svg" width="20" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
