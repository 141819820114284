import { getFilePath, getFilename } from './index';

export enum ReaderEnum {
  TEXT = 'TEXT',
  FILE = 'FILE',
  URL = 'URL',
  GOOGLE_DOCS = 'GOOGLE_DOCS',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  MICROSOFT_ONE_DRIVE = 'MICROSOFT_ONE_DRIVE',
  MICROSOFT_SHARE_POINT = 'MICROSOFT_SHARE_POINT',
  NOTION_PAGE = 'NOTION_PAGE',
  SLACK = 'SLACK',
  GITHUB_REPOSITORY = 'GITHUB_REPOSITORY',
  GITHUB_ISSUE = 'GITHUB_ISSUE',
  BOX = 'BOX',
  YOUTUBE = 'YOUTUBE',
  READWISE = 'READWISE',
  TELEGRAM = 'TELEGRAM',
  CONFLUENCE = 'CONFLUENCE',
  GMAIL = 'GMAIL',
  GOOGLE_SHEETS = 'GOOGLE_SHEETS',
  DISCORD = 'DISCORD'
}
export const ReaderName = {
  [ReaderEnum.TEXT]: 'Text',
  [ReaderEnum.FILE]: 'File',
  [ReaderEnum.URL]: 'Url',
  [ReaderEnum.GMAIL]: 'Gmail',
  [ReaderEnum.GOOGLE_DOCS]: 'Google Docs',
  [ReaderEnum.GOOGLE_SHEETS]: 'Google Sheets',
  [ReaderEnum.GOOGLE_DRIVE]: 'Google Drive',
  [ReaderEnum.MICROSOFT_ONE_DRIVE]: 'Microsoft One Drive',
  [ReaderEnum.MICROSOFT_SHARE_POINT]: 'Microsoft Share Point',
  [ReaderEnum.NOTION_PAGE]: 'Notion Page',
  [ReaderEnum.DISCORD]: 'Discord',
  [ReaderEnum.SLACK]: 'Slack',
  [ReaderEnum.GITHUB_REPOSITORY]: 'Github Repository',
  [ReaderEnum.GITHUB_ISSUE]: 'Github Issue',
  [ReaderEnum.BOX]: 'Box',
  [ReaderEnum.YOUTUBE]: 'YouTube',
  [ReaderEnum.READWISE]: 'Readwise',
  [ReaderEnum.TELEGRAM]: 'Telegram',
  [ReaderEnum.CONFLUENCE]: 'Confluence'
};

export const ReadersIcons = {
  [ReaderEnum.FILE]: '/ingest/txt-file.svg',
  [ReaderEnum.URL]: '/ingest/web.png',
  [ReaderEnum.GOOGLE_DOCS]: '/ingest/docx-file.svg',
  [ReaderEnum.GOOGLE_DRIVE]: '/ingest/drive-icon.svg',
  [ReaderEnum.SLACK]: '/ingest/slack-icon.svg',
  [ReaderEnum.GITHUB_REPOSITORY]: '/ingest/github-icon.svg',
  [ReaderEnum.GITHUB_ISSUE]: '/ingest/github-icon.svg',
  [ReaderEnum.NOTION_PAGE]: '/ingest/notion-icon.svg',
  [ReaderEnum.GMAIL]: '/ingest/gmail-icon.svg',
  [ReaderEnum.GOOGLE_SHEETS]: '/ingest/sheets-icon.svg',
  [ReaderEnum.MICROSOFT_ONE_DRIVE]: '/public-brand-logo/ms-onedrive.svg',
  [ReaderEnum.MICROSOFT_SHARE_POINT]: '/public-brand-logo/ms-sharepoint.svg',
  [ReaderEnum.DISCORD]: '/ingest/discord-icon.svg',
  [ReaderEnum.BOX]: '/public-brand-logo/ms-box.svg',
  [ReaderEnum.YOUTUBE]: '/public-brand-logo/youtube.svg',
  [ReaderEnum.READWISE]: '/ingest/readwise-icon.svg',
  [ReaderEnum.TELEGRAM]: '/ingest/telegram-icon.svg',
  [ReaderEnum.CONFLUENCE]: '/public-brand-logo/confluence.svg'
};
export const ReloadAbleReaders = {
  [ReaderEnum.DISCORD]: true,
  [ReaderEnum.SLACK]: true,
  [ReaderEnum.GITHUB_ISSUE]: true,
  [ReaderEnum.GITHUB_REPOSITORY]: true,
  [ReaderEnum.GOOGLE_DOCS]: true,
  [ReaderEnum.GOOGLE_DRIVE]: true,
  [ReaderEnum.GOOGLE_SHEETS]: true,
  [ReaderEnum.GMAIL]: true,
  [ReaderEnum.MICROSOFT_ONE_DRIVE]: true,
  [ReaderEnum.MICROSOFT_SHARE_POINT]: true,
  [ReaderEnum.NOTION_PAGE]: true,
  [ReaderEnum.BOX]: true,
  [ReaderEnum.YOUTUBE]: true,
  [ReaderEnum.READWISE]: true,
  [ReaderEnum.TELEGRAM]: true,
  [ReaderEnum.CONFLUENCE]: true
};

export const ingestConfig = {
  splitter: 'sentence',
  chunks: 512
};

export function extractIngest(ingest: any) {
  let ingestFullPath = '';
  let ingestName = 'Not Found';
  const ingestIcon = ReadersIcons[ingest?.reader_type];

  const creator = ingest?.creator || 'No name';
  const createdAt = ingest?.created_at;
  const status = ingest?.status;

  const readerType = ingest?.reader_type;

  switch (ingest?.reader_type) {
    case ReaderEnum.FILE:
      // eslint-disable-next-line no-case-declarations
      const file = ingest?.extra_data?.file;
      // eslint-disable-next-line no-case-declarations
      const { name } = getFilename(file?.name);

      ingestName = name;
      ingestFullPath = getFilePath(file?.file_path);
      break;

    case ReaderEnum.URL:
      ingestFullPath = ingest?.extra_data?.url?.link;
      ingestName = ingest?.extra_data?.url?.name;
      break;

    case ReaderEnum.GOOGLE_DRIVE:
      ingestFullPath = ingest?.extra_data?.google_drive?.link;

      ingestName = ingest?.extra_data?.google_drive?.file_name;
      break;

    case ReaderEnum.GOOGLE_DOCS:
      ingestFullPath = ingest?.extra_data?.google_docs?.link;

      ingestName = ingest?.extra_data?.google_docs?.file_name;
      break;

    case ReaderEnum.SLACK:
      ingestFullPath = ingest?.extra_data?.slack?.chanel_link;
      ingestName = ingest?.extra_data?.slack?.chanel_name;
      break;

    case ReaderEnum.GITHUB_REPOSITORY:
      ingestFullPath = ingest?.extra_data?.github_repository?.link;
      ingestName = ingest?.extra_data?.github_repository?.repo;
      break;

    case ReaderEnum.GITHUB_ISSUE:
      ingestFullPath = ingest?.extra_data?.github_issue?.link;
      ingestName = ingest?.extra_data?.github_issue?.repo;
      break;

    case ReaderEnum.NOTION_PAGE:
      ingestFullPath = ingest?.extra_data?.notion_page?.link;
      ingestName = ingest?.extra_data?.notion_page?.name;
      break;

    case ReaderEnum.MICROSOFT_SHARE_POINT:
      ingestFullPath = ingest?.extra_data?.sharepoint.link;
      ingestName = ingest?.extra_data?.sharepoint?.sharepoint_site_name;
      break;

    case ReaderEnum.MICROSOFT_ONE_DRIVE:
      ingestFullPath = ingest?.extra_data?.onedrive?.link;
      ingestName = ingest?.extra_data?.onedrive?.folder_id;
      if (ingest?.extra_data?.onedrive?.file_path) {
        ingestName = ingest?.extra_data?.onedrive?.file_path.split('/').slice(-2, -1)[0];
      }
      break;

    case ReaderEnum.BOX:
      ingestFullPath = ingest?.extra_data?.box?.link;
      ingestName = ingest?.extra_data?.box?.folder_id;
      if (ingest?.extra_data?.box?.path_collection) {
        ingestName = ingest?.extra_data?.box?.path_collection;
      }
      break;

    case ReaderEnum.YOUTUBE:
      ingestFullPath = ingest?.extra_data?.youtube?.link;
      ingestName = ingest?.extra_data?.youtube?.name;
      break;

    case ReaderEnum.READWISE:
      ingestFullPath = ingest?.extra_data?.readwise?.link;
      break;

    case ReaderEnum.TELEGRAM:
      ingestFullPath = ingest?.extra_data?.telegram?.channel_link;
      ingestName = ingest?.extra_data?.telegram?.link;
      break;

    case ReaderEnum.CONFLUENCE:
      ingestFullPath = ingest?.extra_data?.confluence?.base_url;
      ingestName = ingest?.extra_data?.confluence?.name;
      break;

    default:
      break;
  }

  return { ingestName, ingestFullPath, creator, createdAt, status, readerType, ingestIcon };
}

const githubRepoRegex = /^(?:https?:\/\/)?github\.com\/([^/]+)\/([^/]+)/;

export function parseGithubRepositoryUrl(url: string) {
  let username: string | null = null;
  let repoName: string | null = null;

  const match = url.match(githubRepoRegex);

  if (match) {
    username = match[1];
    repoName = match[2];
  }

  return { username, repoName };
}

const googleDriveLinkRegex = /https:\/\/drive\.google\.com\/drive\/folders\/([a-zA-Z0-9-_]+)/;

export function parseGoogleDriveFolderUrl(url: string) {
  let folderId: string | null = null;

  const match = url.match(googleDriveLinkRegex);

  if (match && match[1]) {
    folderId = match[1];
  }

  return { folderId };
}

const googleDocsLinkRegex = /https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9-_]+)/;

export function parseGoogleDocsUrl(url: string) {
  let docId: string | null = null;

  const match = url.match(googleDocsLinkRegex);

  if (match && match[1]) {
    docId = match[1]; // Return the document ID
  }

  return { docId };
}
