import { createSlice } from '@reduxjs/toolkit';

import { IChatWidget, ICustomerUser, ICustomerMessage } from 'types/customerChat';

export interface ICustomerUserObj extends ICustomerUser {
  messages: ICustomerMessage[];
  messageCursor: null | number;
}

interface ICustomerChatStore {
  chatWidget: IChatWidget | null;
  customers: ICustomerUserObj[];
  customerUserCursor: null | number;
  chatWidgets: IChatWidget[];
}

const initialState: ICustomerChatStore = {
  chatWidget: null,
  chatWidgets: [],
  customers: [],
  customerUserCursor: null
};

const findCustomerUser = (customerUsers: ICustomerUserObj[], customerId: number) => {
  return customerUsers.find((customerUser) => customerUser.id == customerId);
};

const getOrderedCustomers = (customers: ICustomerUserObj[], customer: ICustomerUserObj) => {
  const excludedCustomers = customers.filter((obj) => obj.id != customer.id);

  return [customer, ...excludedCustomers];
};

const customerChat = createSlice({
  name: 'customerChat',
  initialState,
  reducers: {
    setChatWidget: (state: ICustomerChatStore, action) => {
      state.chatWidget = action.payload;
    },
    setChatWidgets: (state: ICustomerChatStore, action) => {
      state.chatWidgets = action.payload?.results || [];
    },
    addMessage: (state: ICustomerChatStore, action) => {
      const message: ICustomerMessage = action.payload;
      const customerId = message.customer_id;

      if (!message.customer) {
        console.log('message.customer is null');
        return;
      }

      let customerUser = findCustomerUser(state.customers, customerId);

      if (!customerUser) {
        // Adding new object
        customerUser = {
          messages: [],
          messageCursor: null,
          ...message.customer
        };
      }

      if (!customerUser.messages) {
        customerUser.messages = [];
        customerUser.messageCursor = 0;
      }

      const isAlreadyExists = customerUser.messages.some((obj) => obj.id == message.id);

      if (!isAlreadyExists) {
        customerUser.messages = [message, ...customerUser.messages];
        if (customerUser.last_message) {
          customerUser.last_message.short_message = message.content;
        } else {
          customerUser.last_message = {
            message_id: message.id,
            short_message: message.content,
            created_at: null
          };
        }
      }

      customerUser.user_unread_message = message.customer.user_unread_message;
      customerUser.customer_unread_message = message.customer.customer_unread_message;

      state.customers = getOrderedCustomers(state.customers, customerUser);
    },
    editMessage: (state: ICustomerChatStore, action) => {
      const message: ICustomerMessage = action.payload;
      const editedContent = message.content;

      const customerUser = findCustomerUser(state.customers, message.customer_id);

      if (customerUser) {
        customerUser.messages.forEach((obj) => {
          if (obj.id == message.id) {
            obj.content = editedContent;
          }
        });
      }
    },
    deleteMessage: (state: ICustomerChatStore, action) => {
      const message: ICustomerMessage = action.payload;
      const customerUser = findCustomerUser(state.customers, message.customer_id);

      if (customerUser) {
        customerUser.messages = customerUser.messages.filter((obj) => obj.id != message.id);
      }
    },
    setCustomerMessages: (state: ICustomerChatStore, action) => {
      const messages = action.payload.results;
      const messageCursor = action.payload.after;
      const customerId = action.payload.customerId;

      const customerUser = findCustomerUser(state.customers, customerId);

      if (customerUser) {
        customerUser.messages = messages;
        customerUser.messageCursor = messageCursor;
      }
    },
    setCustomers: (state: ICustomerChatStore, action) => {
      state.customers = action.payload.results;
    },
    addCustomer: (state: ICustomerChatStore, action) => {
      state.customers = [action.payload, ...state.customers];
    },
    updateCustomerUser: (state: ICustomerChatStore, action) => {
      const customerUser = action.payload;
      state.customers.forEach((obj) => {
        if (obj.id == customerUser.id) {
          obj.phone_number = customerUser.phone_number;
          obj.full_name = customerUser.full_name;
          obj.name = customerUser.name;
          obj.is_manual_message = customerUser.is_manual_message;
          obj.user_unread_message = customerUser.user_unread_message;
          obj.customer_unread_message = customerUser.customer_unread_message;
        }
      });
    }
  }
});

export { findCustomerUser };

export const {
  setChatWidget,
  setChatWidgets,
  addMessage,
  editMessage,
  deleteMessage,
  setCustomers,
  addCustomer,
  setCustomerMessages,
  updateCustomerUser
} = customerChat.actions;

export default customerChat.reducer;
