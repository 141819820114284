import React, { useEffect, useMemo, useState } from 'react';

import { Subscription } from '../../apis/subscription';
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';

import './Subscription.css';

export const Subscriptions = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  const freeSubscription = useMemo(() => {
    const freeSubscriptions = subscriptions.filter((item: any) => item.plan_type === 'FREE');

    if (freeSubscriptions.length >= 0) {
      return freeSubscriptions[0];
    }

    return null;
  }, [subscriptions]);

  const monthlyPremiumSubscriptions = useMemo(() => {
    return subscriptions.filter(
      (item: any) => item.duration_type === 'MONTHLY' && item.plan_type !== 'FREE'
    );
  }, [subscriptions]);

  const yearlyPremiumSubscriptions = useMemo(() => {
    return subscriptions.filter(
      (item: any) => item.duration_type === 'YEARLY' && item.plan_type !== 'FREE'
    );
  }, [subscriptions]);

  const getSubscriptions = async () => {
    const data = await Subscription.getSubscriptions();
    if (data) {
      setSubscriptions(data.results);
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleCheckboxChange = () => {
    setIsMonthly(!isMonthly);
  };

  let subscriptionList: any = [];

  if (freeSubscription != null) {
    if (isMonthly) {
      subscriptionList = [freeSubscription, ...yearlyPremiumSubscriptions];
    } else {
      subscriptionList = [freeSubscription, ...monthlyPremiumSubscriptions];
    }
  }

  return (
    <div>
      <div className="flex justify-center">
        <h1 className="selectPackageText">Select a Package</h1>
      </div>

      <div className="flex justify-center mt-12">
        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
          <input
            type="checkbox"
            name="autoSaver"
            className="sr-only"
            checked={isMonthly}
            onChange={handleCheckboxChange}
          />
          <span
            className="label flex items-center text-sm font-medium text-white"
            style={{ color: isMonthly ? '#8297AE' : 'white' }}
          >
            <span className="mr-4">Monthly </span>
          </span>
          <span
            className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200`}
            style={{ border: '1px solid #5F6FFF' }}
          >
            <span
              style={{ backgroundColor: '#5F6FFF' }}
              className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                isMonthly ? 'translate-x-6' : ''
              }`}
            ></span>
          </span>
          <span
            className="label flex items-center text-sm font-medium text-white"
            style={{ color: isMonthly ? 'white' : '#8297AE' }}
          >
            <span className="pl-1">Yearly </span>
          </span>
        </label>
      </div>
      <div className="flex justify-center gap-2 mt-12">
        {subscriptionList.map((subscription: any) => {
          return (
            <SubscriptionCard
              key={`subscriptions-${subscription.id}`}
              id={subscription.id}
              packageName={subscription.name}
              description={subscription.description}
              price={subscription.price}
              plan_type={subscription.plan_type}
              benefits={[
                { num: subscription.total_allowed_ingest_token, msg: 'allowed page' },
                {
                  num: subscription.total_allowed_token,
                  msg: 'total allowed tokens'
                },
                {
                  num: subscription.total_allowed_project,
                  msg: 'allowed project'
                }
              ]}
            />
          );
        })}
        {/* <SubscriptionCard packageName='Basic' description='Ideal for individual or trial use' price='Free' benefits={["aaaa"]} backgroundColor='rgba(255, 255, 255, 0.03);' />
          <SubscriptionCard packageName='Basic' description='Ideal for individual or trial use' price='Free' benefits={["aaaa"]} backgroundColor='rgba(18, 24, 33, 0.90)'/> */}
      </div>

      <p className="contact mt-8">
        Feel free to contact us for <span className="plan">Custom Plan</span>
      </p>
    </div>
  );
};
