import { extractIngest } from 'core/helpers/ingest';
import React from 'react';

const IngestFiles = ({ ingests }: { ingests: any[] }) => {
  if (!ingests) return null;
  return (
    <ul className="neu-ingest">
      {ingests.map((ingest, idx) => {
        const { ingestName, ingestFullPath, readerType, ingestIcon } = extractIngest(ingest);

        if (!readerType) {
          return null;
        }

        return (
          <li key={`ingest-file-${idx}`}>
            <a href={ingestFullPath} className="neu-file-link">
              <div>
                <img src={ingestIcon} />
              </div>
              {ingestName}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default IngestFiles;
