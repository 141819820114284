import React, { useState } from 'react';

export const Analyzing = ({ observations }: any) => {
  const [active, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!active);
  };

  return (
    <div className="border border-[#222831] bg-[#0A0D11] rounded-[10px] mb-8">
      <div className="bg-[#191F28] py-[15px] px-[18px] flex items-center justify-between">
        <h4>Agent Working Process</h4>
        <div className="flex items-center gap-2">
          <span className={`cursor-pointer arrow-icon ${active ? 'active' : ''}`} onClick={handleClick}>
            {/* <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 13.75V10.6674H3.20833V12.7917H5.33264V13.75H2.25ZM10.6674 13.75V12.7917H12.7917V10.6674H13.75V13.75H10.6674ZM2.25 5.33264V2.25H5.33264V3.20833H3.20833V5.33264H2.25ZM12.7917 5.33264V3.20833H10.6674V2.25H13.75V5.33264H12.7917Z"
                fill="#637B94"
              />
            </svg> */}
            <img src="/message/down-arrow.png" className='mt-1' width="16" alt="" />
          </span>
        </div>
      </div>
      <div className="p-5">
        <ul className={`analyzing_lists ${active ? 'is-active' : ''}`}>
          {observations?.map((data: string, idx: number) => {
            return (
              <li className="flex gap-2 mb-3 items-start" key={`message-obj-${idx}`}>
                <img src="/analyzer-icon.svg" className='mt-1' width="16" alt="" />
                <p>{data}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
