import React from 'react';

import MarkdownRenderer from 'components/MarkdownRenderer';
import { getFilePath } from 'core/helpers';

interface IProps {
  answer: any;
  project: any;
}

function Answer({ answer, project }: IProps) {
  if (Array.isArray(answer)) {
    answer = answer.join('');
  }

  return (
    <div>
      <div className="flex items-center gap-3 pb-2">
        <img src={getFilePath(project?.image?.file_path)} width={30} />
        <h2 className="text-[16px] text-[#4BE8CB] font-semibold mb-2">{project?.name}</h2>
        <span className="text-[#FCFDFD] text-[11px] bg-[#222831] py-1 px-[10px] rounded-[26px] h-[20px] flex items-center justify-center">
          {project?.type == 'RAG' ? 'Simple Agent' : 'Advance Agent'}
        </span>
      </div>
      <MarkdownRenderer content={answer} />
    </div>
  );
}

export default Answer;
